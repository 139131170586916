<template>
    <div class="main-container">
      <h5 class="pageTitle">발송 메시지 수정/삭제</h5>
  
      <div class="form-container">
        <div class="form-group">
          <label for="senderNumber">발신번호</label>
          <input v-model="formData.senderPhone" type="text" class="input-box" placeholder="발신번호를 입력하세요" />
        </div>
        
        <div class="form-group">
          <label for="receiverNumber">수신번호</label>
          <input v-model="formData.receiverPhone" type="text" class="input-box" placeholder="수신번호를 입력하세요" />
        </div>
  
        <div class="form-group">
          <label for="sendType">발송형태</label>
          <div class="inline-container">
          <input v-model="sendType" type="text" class="input-box" :placeholder="sendType === '일반' ? '예약' : '발송형태를 선택하세요'" readonly />
            <div class="type-toggle">
              <button @click="setSendType('일반')" :class="{ active: sendType === '일반' }">일반</button>
              <button @click="setSendType('예약')" :class="{ active: sendType === '예약' }">예약</button>
            </div>
          </div>
        </div>
  
        <div class="form-group" v-if="formData.status === '예약'">
          <label for="sendDate">발송일시</label>
          <input v-model="formData.sendTime" type="datetime-local" class="input-box wide-input" />
        </div>
        <!-- 발송일시 -->
        <div class="form-group" v-if="sendType === '예약'">
            <label for="sendDate">발송일시</label>
            <input
            v-model="formData.sendTime"
            type="datetime-local"
            class="input-box wide-input"
            />
            <!-- 읽기 전용 텍스트 입력 -->
            <input
              type="text"
              class="input-box wide-input readonly-text"
              :value="formatDateToDisplay(formData.sendTime)"
              readonly
            />
        </div>
        <div class="form-group">
          <label for="messageContent">메 시 지</label>
          
          <textarea v-model="formData.messageBody" @input="handleInput" rows="3" maxlength="90" class="textarea-box" placeholder="90바이트 이내에 메시지를 입력하세요"></textarea>
          <div class="char-count">현재 글자 수: {{ byteCount }} / 90 바이트</div>
          
          
        </div>
  
        <div class="button-container">
          <button class="button-list" @click="goToList">목록</button>
          <button class="button-update" @click="updateMessage">수정</button>
            <button class="button-delete" @click="deleteMessage">삭제</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "@/axios";
  import Swal from 'sweetalert2';
  
  export default {
   
    props: {
      cmid: String,
      requestTime: String,
      sendTime: String,
      senderPhone: String,
      receiverPhone: String,
      messageBody: String,
      status: String,
      errorCode: String,
    },


    data() {
      return {
        formData: {
            cmid: this.cmid || "",
            requestTime: this.requestTime || "",
            sendTime: this.sendTime || "",
            senderPhone: this.senderPhone || "",
            receiverPhone: this.receiverPhone || "",
            messageBody: this.messageBody || "",
            status: this.status || "일반", // 기본값 설정
            errorCode: this.errorCode || "-",
        },
        sendType: this.status === "대기" ? "예약" : "일반", // 기본값 설정
        byteCount: 0, // 현재 입력된 메시지 바이트 수
      };
    },
    computed:{
      senderNumber() {
        return this.$store.state.senderNumber; // Vuex state에서 발신 번호 가져오기
      },      
    },
    created(){
      console.log("초기 데이터:", this.formData);
      // 발신 번호 초기 설정 (필요시)
      if (!this.senderNumber) {
        console.log('기본 발신 번호를 설정합니다:', this.senderNumber);
      }
       // 바이트 수 계산 및 설정
      this.byteCount = this.calculateByteLength(this.formData.messageBody);
      console.log("초기 메시지 바이트 수:", this.byteCount);
      // if (!this.messageData) {
      //       Swal.fire("오류", "수정할 데이터를 불러오지 못했습니다.", "error");
      //       this.goToList();
      // } else {
        //console.log("받은 데이터:", this.messageData);
        // cmid 값 확인
        //console.log("cmid 값:", this.messageData.cmid);

        // this.formData = { ...this.messageData };

        // // 발송 타입 초기화
         //this.sendType = this.messageData.status === "대기" ? "예약" : "일반";

        // // 날짜 변환 및 불필요한 값 제거
        // this.formData.sendTime = this.cleanAndFormatDateTime(this.messageData.sendTime);
        // console.log("변환된 발송일시:", this.formData.sendTime);
     // }
    
     

    },

    methods: {
      formatDateToDisplay(dateTime) {
        if (!dateTime) return "";

        const date = new Date(dateTime);
        if (isNaN(date.getTime())) return dateTime; // 유효하지 않은 날짜 처리

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, "0");

        const period = hours >= 12 ? "오후" : "오전";
        hours = hours % 12 || 12; // 12시간 형식

        return `${year}-${month}-${day} ${period} ${hours}:${minutes}`;
      },
     
      updateSenderNumber(newNumber) {
        this.$store.commit('setSenderNumber', newNumber); // 발신 번호 변경
      },
      setSendType(type) {
        this.sendType = type;
        if (type === "일반") {
            // "일반" 상태일 경우 현재 시간을 설정
            const now = new Date();
            this.formData.sendTime = `${now.getFullYear()}-${String(
            now.getMonth() + 1
          ).padStart(2, "0")}-${String(now.getDate()).padStart(2, "0")}T${String(
            now.getHours()
          ).padStart(2, "0")}:${String(now.getMinutes()).padStart(2, "0")}`;
        }
      },
      goToList() {
        this.$router.push('/sms-read');
      },
      calculateByteLength(text) {
        let byteCount = 0;
        for (let i = 0; i < text.length; i++) {
          const char = text[i];
          byteCount += char.match(/[가-힣]/) ? 2 : 1; // 한글은 2바이트, 영문/숫자/특수문자는 1바이트
        }
        return byteCount;
      },
      handleInput(event) {
        const inputValue = event.target.value;
        const calculatedByteCount = this.calculateByteLength(inputValue);

        // 90바이트를 초과하면 입력 금지
        if (calculatedByteCount > 90) {
          Swal.fire('오류', '메시지는 90바이트를 넘을 수 없습니다.', 'error');
          // 초과된 내용 제거
          event.target.value = this.messageContent;
        } else {
          this.messageContent = inputValue;
          this.byteCount = calculatedByteCount;
        }
      },

     
    formatLocalizedDateToISO(dateString) {
        if (!dateString) {
          console.error("Date value is null or undefined:", dateString);
          return null; // 값이 없으면 null 반환
        }

        try {
          console.log("Parsing localized date string:", dateString);

          // 이미 ISO 8601 형식인 경우 변환하지 않음
          const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}(:\d{2})?$/;
          if (isoRegex.test(dateString)) {
            console.log("Already in ISO 8601 format:", dateString);
            return dateString;
          }

          // 정규식을 통해 날짜와 시간 추출
          const dateParts = dateString.match(/(\d{4})\.\s*(\d{2})\.\s*(\d{2})\.?\s*(오전|오후)?\s*(\d{1,2}):(\d{2}):?(\d{2})?/);
          if (!dateParts || dateParts.length < 7) {
            throw new Error(`Unexpected date format: ${dateString}`);
          }

          // 배열의 정확한 위치에서 데이터 추출
          const year = parseInt(dateParts[1], 10);
          const month = parseInt(dateParts[2], 10) - 1; // 월은 0부터 시작
          const day = parseInt(dateParts[3], 10);
          let hours = parseInt(dateParts[5], 10);
          const minutes = parseInt(dateParts[6], 10);
          const seconds = parseInt(dateParts[7] || "00", 10);
          const period = dateParts[4]; // 오전/오후

          // 오전/오후 처리
          if (period === "오후" && hours < 12) {
            hours += 12;
          } else if (period === "오전" && hours === 12) {
            hours = 0;
          }

          console.log('year:' + year);
          console.log('month:' + month);
          console.log('day:' + day);
          console.log('hours:' + hours);
          console.log('minutes:' + minutes);
          console.log('seconds:' + seconds);

          // JavaScript의 Date 객체 생성
          const isoDate = new Date(year, month, day, hours, minutes, seconds).toISOString();
          console.log("Converted ISO Date:", isoDate);

          return isoDate;
        } catch (error) {
          console.error("Invalid localized date format:", dateString, error);
          return null;
        }
      }
      ,


      async updateMessage() {
        const tokenData = JSON.parse(sessionStorage.getItem('token'));
        const token = tokenData ? tokenData.access_token : '';
        
        const sendTimeISO = this.formatLocalizedDateToISO(this.formData.sendTime);
        console.log(this.formData.sendTime);
        if (!sendTimeISO) {
          Swal.fire("오류", "발송 시간이 유효하지 않습니다.", "error");
          return;
        }
          try {
          
            const config = {
              method: "put",
              url: `/sms-main/update_message`,
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
              data: {
                cmid: this.formData.cmid, // 수정할 대상의 ID
                dest_phone: this.formData.receiverPhone, // 수신 번호
                send_phone: this.formData.senderPhone, // 발신 번호
                msg_body: this.formData.messageBody, // 메시지 내용               
                send_time: sendTimeISO, // 발송 시간
              },
            };

            await axios(config);
            Swal.fire("성공", "메시지가 수정되었습니다.", "success");
            this.goToList();
          } catch (error) {
            Swal.fire("오류", "메시지 수정 중 오류가 발생했습니다.", "error");
            console.error(error);
          }
        },
        async deleteMessage() {
          const tokenData = JSON.parse(sessionStorage.getItem('token'));
          const token = tokenData ? tokenData.access_token : '';
            try {
                    const config = {
                    method: "delete",
                    url: `/sms-main/delete_message/${this.formData.cmid}`, // 삭제 API URL
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                };
                await axios(config);
                Swal.fire("성공", "메시지가 삭제되었습니다.", "success");
                this.goToList();
            } catch (error) {
                Swal.fire("오류", "메시지 삭제 중 오류가 발생했습니다.", "error");
                console.error(error);
            }
        },
      
    },
  };
  </script>
  
  <style scoped>
  .main-container {
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .pageTitle {
    font-weight: bold;
    margin-bottom: 40px;
    text-align: left;
    width: 100%;
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 70%;
    max-width: 800px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* gap: 10px;
    flex-wrap: nowrap; */
    width: 100%;
  }
  
  .input-box {
    flex: 1;
    padding: 8px;
    margin-right: 40px;
    border: 1px solid rgba(130, 128, 128, 0.26);
    border-radius: 3px;
    max-width: 200px;
  }
  .input-box.wide-input {
    max-width: 300px; /* 발송일시 칸을 조금 더 넓게 */
  }
  .char-count {
    font-size: 0.9em;
    margin-bottom: 5px;
    color: #555;
  }
  .textarea-box {
    padding: 8px;
    border: 1px solid rgba(130, 128, 128, 0.26);
    border-radius: 3px;
    width: 290px;
    height: 90px;     
    resize: none;
  }
  
  .button-container {
    display: flex;
    gap: 50px;
    justify-content: flex-start;
  }
  
  .button-list {
    background-color: #ff9800;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  .button-update {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  .button-delete {
    background-color: #f44336;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .inline-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .type-toggle button {
    background-color: #e0e0e0;
    border: 1px solid #ccc;
    padding: 5px 10px;
    margin-right: 5px;
    cursor: pointer;
  }
  
  .type-toggle button.active {
    background-color: #b2b1fc;
    color: white;
  }

  .readonly-text {
    color: gray;
    background-color: #f5f5f5; /* 약간의 비활성화 느낌 */
    border: 1px solid #dcdcdc; /* 경계 색 */
    cursor: not-allowed; /* 커서 비활성화 */
  }
  </style>
  